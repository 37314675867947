import React from "react";
import Hero from "./Hero";
import TopFund from "./TopFund";
import MyNft from "./MyNft";
import Wagmi from "./Wagmi";
import HowUnique from "./HowUnique";
import { useOutletContext } from "react-router-dom";

const Home = () => {
  const [
    siteDetails,
    walletAddress,
    connectWallet,
    isWalletConnected,
    nftData,
    ,
    nftTop,
    loading,
    topLoading,
    ,
    ,
  ] = useOutletContext();
  return (
    <div>
      <Hero siteDetails={siteDetails} />
      <TopFund
        siteDetails={siteDetails}
        connectWallet={connectWallet}
        isWalletConnected={isWalletConnected}
        nftTop={nftTop}
        loading={topLoading}
      />
      <MyNft
        walletAddress={walletAddress}
        nftData={nftData}
        loading={loading}
      />
      <HowUnique />
      <Wagmi />
    </div>
  );
};

export default Home;
