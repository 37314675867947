import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper } from "swiper/react";
import { Navigation } from "swiper";
import SimpleCard from "../SimpleCard";
import { NavLink } from "react-router-dom";
import Loader from "../Loader";
import Slider from "react-slick";

const MyNft = ({ walletAddress, nftData = [], loading = true }) => {
  //const [nftData, setNftData] = useState(nft);
  //const [loading, setLoading] = useState(false);

  const settings = {
    infinite: false,
    speed: 300,
  };
  const renderCardData = () => {
    //need to add the lenght of nftData
    if (walletAddress) {
      if (loading) {
        return (
          <div className="nft-cards">
            <Loader loading={loading} />
          </div>
        );
      } else {
        if (nftData.length === 0)
          return (
            <h1 className="no-nft-text">
              <NavLink to="/buy">Buy</NavLink> or{" "}
              <NavLink to="/mint">mint</NavLink> your first Catdrop!
            </h1>
          );
        else if (nftData.length <= 3) {
          return (
            <>
              <div className="my-nfts" style={{ justifyContent: "center" }}>
                {nftData.map((simpleCard) => (
                  <div
                    className="nft-grid-simplecard__wrapper"
                    key={simpleCard.id}
                  >
                    <SimpleCard
                      key={simpleCard.id}
                      simpleCard={simpleCard}
                      overlayText={simpleCard.forSale}
                    />
                  </div>
                ))}
              </div>
            </>
          );
        } else {
          return (
            <>
              <div className="mynft__swipper">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={50}
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <div className="swiper-wrapper swiper-wrap">
                    {nftData.map((simpleCard) => (
                      <div key={simpleCard.id} className="swiper-slide">
                        <SimpleCard
                          key={simpleCard.id}
                          simpleCard={simpleCard}
                          overlayText={simpleCard.forSale}
                        />
                      </div>
                    ))}
                  </div>
                </Swiper>
              </div>
            </>
          );
        }
      }
    } else {
      return (
        <h1 className="no-nft-text">
          <NavLink to="/buy" className="link-design">
            Buy
          </NavLink>{" "}
          or{" "}
          <NavLink to="/mint" className="link-design">
            mint
          </NavLink>{" "}
          your first Catdrop!
        </h1>
      );
    }
  };

  const renderMobileCardData = () => {
    //need to add the lenght of nftData
    if (walletAddress) {
      if (loading) {
        return (
          <div className="nft-cards">
            <Loader loading={loading} />
          </div>
        );
      } else {
        if (nftData.length === 0)
          return (
            <h1 className="no-nft-text">
              <NavLink to="/buy">Buy</NavLink> or{" "}
              <NavLink to="/mint">mint</NavLink> your first Catdrop!
            </h1>
          );
        else {
          return (
            <Slider {...settings}>
              {nftData.map((card) => (
                <SimpleCard
                  key={card.id}
                  simpleCard={card}
                  overlayText={card.forSale}
                />
              ))}
            </Slider>
          );
        }
      }
    } else {
      return (
        <h1 className="no-nft-text">
          <NavLink to="/buy" className="link-design">
            Buy
          </NavLink>{" "}
          or{" "}
          <NavLink to="/mint" className="link-design">
            mint
          </NavLink>{" "}
          your first Catdrop!
        </h1>
      );
    }
  };

  return (
    <>
      <span className="link_wrapper" id="my"></span>
      <section className="default-settings">
        <div className="mynft__title-box">
          <div className="mynft__title-column column1"></div>
          <div className="mynft__title-column column2">
            <h1 className="mynft__title">My Catdrops</h1>
          </div>
          <div className="mynft__title-column column3">
            {nftData.length !== 0 ? (
              <NavLink to="/my">
                <h3 className="mynft__viewall">View All and Sell</h3>
              </NavLink>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mynft-cards">{renderCardData()}</div>
        <div className="mynft-cards-mobile">{renderMobileCardData()}</div>
      </section>
    </>
  );
};

export default MyNft;
